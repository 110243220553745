import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const TOS_ASSET_NAME = 'terms-of-service';
export const PRIVACY_POLICY_ASSET_NAME = 'privacy-policy';

import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';

export const SET_INITIAL_STATE = 'app/AuthenticationPage/SET_INITIAL_STATE';

export const FETCH_LAST_LISTING_REQUEST = 'app/AuthenticationPage/FETCH_LAST_LISTING_REQUEST';
export const FETCH_LAST_LISTING_SUCCESS = 'app/AuthenticationPage/FETCH_LAST_LISTING_SUCCESS';
export const FETCH_LAST_LISTING_ERROR = 'app/AuthenticationPage/FETCH_LAST_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

const getImageVariantInfo = listingImageConfig => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  const fieldsImage = [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`, 'variants.square-small', 'variants.square-small2x'];

  return {
    fieldsImage,
    imageVariants: {
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    },
  };
};

// ================ Reducer ================ //

const initialState = {
  lastPublishedListing: [],
  fetchLastListingError: null,
  fetchLastListingInProgress: false,
  lastPublishedListingReviews: [],
  fetchReviewsError: null,
};

export default function authenticationPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case FETCH_LAST_LISTING_REQUEST:
      return {
        ...state,
        fetchLastListingInProgress: true,
        fetchLastListingError: null,
      };
    case FETCH_LAST_LISTING_SUCCESS:
      return {
        ...state,
        lastPublishedListing: payload.data,
        fetchLastListingInProgress: false,
      };
    case FETCH_LAST_LISTING_ERROR:
      return {
        ...state,
        fetchLastListingInProgress: false,
        fetchLastListingError: payload,
      };

    case FETCH_REVIEWS_REQUEST:
      return {
        ...state,
        fetchReviewsError: null
      };
    case FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        lastPublishedListingReviews: payload
      };
    case FETCH_REVIEWS_ERROR:
      return {
        ...state,
        fetchReviewsError: payload
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const fetchLastListingRequest = queryParams => ({
  type: FETCH_LAST_LISTING_REQUEST,
  payload: { queryParams },
});

export const fetchLastListingSuccess = response => ({
  type: FETCH_LAST_LISTING_SUCCESS,
  payload: { data: response },
});

export const fetchLastListingError = error => ({
  type: FETCH_LAST_LISTING_ERROR,
  error: true,
  payload: error,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchLastListing = queryParams => (dispatch, getState, sdk) => {
  dispatch(fetchLastListingRequest(queryParams));

  return sdk.listings
    .query(queryParams)
    .then(response => {
      const allListings = response.data.data;
      let lastListing = allListings[allListings.length - 1];

      const images =
        response.data.included &&
        response.data.included.filter(i => {
          return i.type === 'image';
        });

      const users =
        response.data.included &&
        response.data.included.filter(i => {
          return i.type === 'user';
        });
      const imageId = lastListing.relationships.images.data[0]?.id.uuid;
      const authorId = lastListing.relationships.author.data?.id.uuid;

      const luckyImage = images.find(i => {
        return i?.id.uuid === imageId;
      });

      const author = users.find(u => {
        return u?.id.uuid === authorId;
      });

      const authorProfileImageId = author.relationships.profileImage.data?.id.uuid;

      const listingImage = images.find(i => {
        return i.id.uuid === imageId;
      });
      const authorProfileImage = images.find(i => {
        return i.id.uuid === authorProfileImageId;
      });

      lastListing = {
        ...lastListing,
        author: {
          ...author,
          profileImage: authorProfileImage
        },
        images: [listingImage]
      };

      dispatch(fetchLastListingSuccess(lastListing));
      return response;
    })
    .catch(e => {
      dispatch(fetchLastListingError(storableError(e)));
      throw e;
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

export const loadData = (params, search, config) => (dispatch, getState) => {
  const pageAsset = {
    termsOfService: `content/pages/${TOS_ASSET_NAME}.json`,
    privacyPolicy: `content/pages/${PRIVACY_POLICY_ASSET_NAME}.json`,
  };

  const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);

  const queryParams = {
    include: ['author', 'images', 'author.profileImage'],
    'fields.image': imageVariantInfo.fieldsImage,
    ...imageVariantInfo.imageVariants,
    'limit.images': 1,
  };

  return dispatch(fetchLastListing(queryParams))
    .then(() => {
      const state = getState();
      const lastPublishedListing = state.AuthenticationPage.lastPublishedListing;

      if (lastPublishedListing && lastPublishedListing.id) {
        const listingId = lastPublishedListing.id.uuid;
        return dispatch(fetchReviews(listingId));
      }

      return null; // Or handle the case where lastPublishedListing is not available
    })
    .then(() => {
      return dispatch(fetchPageAssets(pageAsset, true));
    });
};